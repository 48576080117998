<template>
  <div class="operation-box">
    <div class='img-style-box'>
      <div class='img-style-item'>
        <div class='label'>模块上下间距：</div>
        <a-slider :default-value="0" :min='0' :max='200' :step='1' v-model="props.boxPaddingH" tooltipPlacement='right' style='width:180px;margin:0 12px;' />
        <span>{{props.boxPaddingH}}px</span>

      </div>
      <div class='img-style-item'>
        <div class='label'>文案内容：</div>
        <a-input placeholder='请输入文字' v-model="props.text"></a-input>
      </div>
      <div class='img-style-item'>
        <div class='label'>模块左右边距：</div>
        <a-slider :default-value="0" :min='0' :max='200' :step='1' v-model="props.boxPaddingV" tooltipPlacement='right' style='width:180px;margin:0 12px;' />
        <span>{{props.boxPaddingV}}px</span>
      </div>
      <div class='img-style-item'>
        <div class='label'>标题字体色：</div>
        <a-popover title="">
          <template slot="content">
            <Sketch v-model="props.textColor" @input="e=>$set(props, 'textColor', `rgba(${e.rgba.r},${e.rgba.g},${e.rgba.b},${e.rgba.a})`)"></Sketch>
          </template>
          <div class='bg-btn' :style='{background: props.textColor}'></div>
        </a-popover>
      </div>
      <div class='img-style-item'>
        <div class='label'>背景色：</div>
        <a-popover title="">
          <template slot="content">
            <Sketch v-model="props.bgColor" @input="e=>$set(props, 'bgColor', `rgba(${e.rgba.r},${e.rgba.g},${e.rgba.b},${e.rgba.a})`)"></Sketch>
          </template>
          <div class='bg-btn' :style='{background: props.bgColor}'></div>
        </a-popover>
      </div>
      <div class='img-style-item'>
        <div class='label'>文字大小：</div>
        <a-slider :default-value="0" :min='12' :max='56' :step='1' v-model="props.fontSize" tooltipPlacement='right' style='width:180px;margin:0 12px;' />
        <span>{{props.fontSize}}px</span>
      </div>
      <div class='img-style-item'>
        <div class='label'>对齐方式：</div>
        <a-radio-group v-model="props.textAlign">
          <a-radio value="left">
            左对齐
          </a-radio>
          <a-radio value="center">
            居中
          </a-radio>
          <a-radio value="right">
            右对齐
          </a-radio>
        </a-radio-group>
      </div>
      <div class='img-style-item'>
        <div class='label'>标题粗细：</div>
        <a-radio-group v-model="props.fontWeight">
          <a-radio value="normal">
            常规
          </a-radio>
          <a-radio value="bolder">
            加粗
          </a-radio>
        </a-radio-group>
      </div>
    </div>
  </div>
</template>
<script>
import { Sketch } from 'vue-color'
export default {
  props: {
    props: {
      type: Object
    }
  },
  components: {
    Sketch
  },
  setup (props, { root, emit }) {
    return {}
  }
}
</script>
<style lang="less" scoped>
.operation-box {
  background: #fff;
  .img-style-box {
    background: #fff;
    padding: 32px 14px 200px;
    .img-style-item {
      display: flex;
      align-items: center;
      margin-bottom: 14px;
      .bg-btn {
        width: 32px;
        height: 24px;
        border: 1px solid #ddd;
        cursor: pointer;
      }
      .label {
        width: 7em;
        text-align: right;
      }
      ::v-deep .ant-slider-handle {
        border-color: #189ff0;
      }
      ::v-deep .ant-slider-track {
        background-color: #189ff0;
      }
      ::v-deep .ant-slider-rail {
        background-color: #c1c1c1;
      }
    }
  }
}
</style>
